
    var riot = require('riot')
    
riot.tag2('bookdelegates', '<button if="{!opts.showqty}" type="button" onclick="{handleAdd}" class="btn btn-block btn-sm {opts.btnClass || \'btn-primary\'}">{delegates && delegates.length > 0 ? \'Update (\'+delegates.length+\')\' : \'Book\'}</button> <modal name="delegatemodal" bssize="lg" title="Book Delegates" closetext="Close" savetext="Save Delegates" backdrop="static" class="delegatemodal"> <form onsubmit="{parent.save}" class="form-horizontal"> <table class="table table-striped"> <thead> <tr> <th>First Name </th> <th>Surname</th> <th>Email Address</th> <th if="{parent.opts.showcredits}">Use Credits</th> <th>Remove</th> </tr> </thead> <tbody> <tr each="{val,idx in parent.delegates}"> <td> <input name="first_name" value="{val.first_name}" oninput="{parent.parent.updateField}" required class="form-control"> </td> <td> <input name="surname" value="{val.surname}" oninput="{parent.parent.updateField}" required class="form-control"> </td> <td> <input name="email" value="{val.email}" oninput="{parent.parent.updateField}" required class="form-control" type="{\'email\'}"> </td> <td if="{parent.parent.opts.showcredits}"> <input name="credits" type="checkbox" __checked="{val.credits}" onchange="{parent.parent.updateField}" class="creditscheck"> </td> <td> <confirm onconfirm="{parent.parent.removeDelegate.bind(this,idx)}" class="btn btn-danger"><span class="fa fa-close"></span></confirm> </td> </tr> </tbody> </table> <div if="{parent.error}" class="alert alert-danger">{parent.error}</div> <div style="margin:-15px" class="modal-footer"> <button type="button" onclick="{parent.closeModal}" class="btn btn-default">Close</button> <button type="button" onclick="{parent.addDelegate}" class="btn btn-default">Add Another Delegate</button> <button type="submit" class="btn btn-primary">Continue</button> </div> </form> </modal>', '.delegatemodal .creditscheck { display: block; margin: 10px auto; }', '', function(opts) {
var Formatter, resource, tagSelector;

resource = require('riot-kit/utils/apiResource.coffee');

tagSelector = require('riot-kit/utils/tagSelector.coffee');

Formatter = require('riot-kit/utils/dateformat.coffee');

this.on('mount', (function(_this) {
  return function() {
    _this.basket = tagSelector('basket', 'popup-basket');
    _this.basketResource = new resource(opts.src || '/duocms/api/basket');
    _this.delegateResource = new resource('/duocms/api/event_delegates/' + opts.productid, true);
    if (_this.basket) {
      return _this.basket._tag.on("update", _this.basketModalUpdated);
    } else {
      return _this.getDelegates();
    }
  };
})(this));

this.on('unmount', function() {
  if (this.basket) {
    return this.basket._tag.off("update", this.basketModalUpdated);
  }
});

this.on("update", function() {});

this.basketModalUpdated = (function(_this) {
  return function() {
    return _this.getDelegates();
  };
})(this);

this.getDelegates = (function(_this) {
  return function() {
    if (_this.gettingDelegates) {
      return;
    }
    _this.gettingDelegates = true;
    return _this.delegateResource.get(function(delegates) {
      _this.gettingDelegates = false;
      _this.delegates = delegates != null ? delegates : [];
      return _this.update();
    });
  };
})(this);

this.handleAdd = (function(_this) {
  return function() {
    var ref;
    if (!((ref = _this.delegates) != null ? ref.length : void 0)) {
      _this.delegates = [
        {
          event_id: opts.eventid
        }
      ];
    }
    return _this.tags.delegatemodal.show();
  };
})(this);

this.updateField = (function(_this) {
  return function(e) {
    if (e.target.type === "checkbox") {
      e.item.val[e.target.name] = e.target.checked;
    } else {
      e.item.val[e.target.name] = e.target.value;
    }
    return _this.update();
  };
})(this);

this.addDelegate = (function(_this) {
  return function() {
    _this.delegates.push({
      event_id: opts.eventid
    });
    return _this.update();
  };
})(this);

this.removeDelegate = (function(_this) {
  return function(idx) {
    _this.delegates.splice(idx, 1);
    return _this.update();
  };
})(this);

this.closeModal = (function(_this) {
  return function(e) {
    _this.delegateResource.get(function(delegates1) {
      _this.delegates = delegates1;
      return _this.update();
    });
    return _this.tags.delegatemodal.hide();
  };
})(this);

this.save = (function(_this) {
  return function(e) {
    if (e) {
      e.preventDefault();
    }
    if (_this.basket) {
      return _this.delegateResource.post(_this.delegates, function(res, err) {
        if (err) {
          return _this.update({
            error: err.message
          });
        } else {
          _this.update({
            error: false
          });
          return _this.basketResource.save({
            id: opts.productid,
            qty: _this.delegates.length
          }, function() {
            _this.basket.trigger('updateBasket');
            return _this.tags.delegatemodal.hide();
          });
        }
      });
    }
  };
})(this);
});
    
  