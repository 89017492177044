
    var riot = require('riot')
    
riot.tag2('signup', '<div class="profile"> <form class="form-horizontal"> <div class="row"> <div class="col-xs-12"> <forminput name="first_name" label="First Name" onupdate="{handleUpdate}" value="{user.first_name}" width1="3" width2="9"></forminput> <forminput name="surname" label="Surname" onupdate="{handleUpdate}" value="{user.surname}" width1="3" width2="9"></forminput> <forminput name="email" label="Email" onupdate="{handleUpdate}" value="{user.email}" width1="3" width2="9"></forminput> <div class="row"> <div class="col-xs-9 col-xs-offset-3"> <button onclick="{handleSaveProfile}" class="btn btn-primary">Sign Up</button> </div> </div> <p><br></p> <div if="{message}" class="alert {\'alert-\'+status}">{message}</div> </div> </div> </form> </div>', '', '', function(opts) {
var xhr;

xhr = require("../libs/xhr.coffee");

this.on("mount", function() {
  this.user = {};
  if (window.location.search.match(/editmode=/)) {
    return this.root.innerHTML = "--signup form--";
  }
});

this.handleUpdate = (function(_this) {
  return function(update) {
    _this.user[update.name] = update.value;
    return _this.update();
  };
})(this);

this.handleSaveProfile = (function(_this) {
  return function(e) {
    e.preventDefault();
    return xhr("POST", "/duocms/api/register", _this.user, function(res) {
      var status;
      console.log(res);
      status = res.status === 200 ? "success" : "danger";
      _this.update({
        message: res.body.message || res.body.data.message,
        status: status
      });
      return setTimeout((function() {
        return _this.update({
          message: false
        });
      }), 3000);
    });
  };
})(this);
});
    
  