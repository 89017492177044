
    var riot = require('riot')
    
riot.tag2('site-search', '<div class="site-search"> <form action="/search/" method="GET"> <div class="search {closed:!open,open:open}"><i onclick="{toggle}" class="fa fa-times closebutton {hidden:!open}"></i> <div class="form-group"> <div class="input-group"> <input placeholder="Search Website" name="q" class="form-control"> <div class="input-group-btn"> <button type="submit" class="btn btn-link btn-submit"><i class="fa fa-arrow-right"></i></button> </div> <div class="input-group-btn"> <button type="button" onclick="{toggle}" class="btn btn-link"><i class="fa fa-search"></i></button> </div> </div> </div> </div> </form> </div>', '', '', function(opts) {
this.open = false;

this.toggle = (function(_this) {
  return function() {
    _this.open = !_this.open;
    return _this.q.focus();
  };
})(this);
});
    
  