
    var riot = require('riot')
    
riot.tag2('comments_output', '<div if="{comments.length &gt; 0}" class="comments"> <h2>Comments</h2> <div each="{comment in comments}" class="row comment"> <div class="col-xs-12 col-md-12"> <h6>{comment.name}</h6><small> <dateformat date="{comment.created_at}" format="dd/MM/yyyy HH:mm"></dateformat></small> <p riot-tag="raw" if="{!comment.editing}" content="{parent.fixComments(comment.comments)}"></p> <div if="{comment.editing}" class="form-group"> <label class="control-label">Comments</label> <textarea required value="{comment.comments}" oninput="{parent.updateComment.bind(null, comment)}" rows="5" class="form-control"></textarea> </div> <button if="{parent.opts.showEdit &amp;&amp; !comment.editing}" onclick="{parent.editComment.bind(null,comment)}" class="btn btn-primary btn-sm">Edit</button> <button if="{parent.opts.showEdit &amp;&amp; comment.editing}" onclick="{parent.saveComment.bind(null,comment)}" class="btn btn-primary btn-sm">Save</button> <button if="{parent.opts.showEdit &amp;&amp; !comment.publish}" onclick="{parent.publishComment.bind(null,comment, true)}" class="btn btn-primary btn-sm">Publish</button> <button if="{parent.opts.showEdit &amp;&amp; comment.publish}" onclick="{parent.publishComment.bind(null,comment, false)}" class="btn btn-primary btn-sm">Unpublish</button> <button if="{parent.opts.showEdit &amp;&amp; !comment.publish}" onclick="{parent.removeComment.bind(null,comment)}" class="btn btn-danger btn-sm">Delete</button> </div> </div> </div>', '.comments .comment { border: 1px solid #eee; border-width: 1px 0 0 0; padding-bottom: 10px; } .comments .comment:last-child { border-width: 1px 0; } .comments .comment .profile { padding: 11px; margin: 15px; border: 1px solid #eee; }', '', function(opts) {
var api, resource;

resource = require('riot-kit/utils/apiResource.coffee');

api = new resource('/duocms/api/comments', true);

this.on('mount', function() {
  return this.getAllComments();
});

this.fixComments = (function(_this) {
  return function(comments) {
    return comments.replace(/\r\n|\r|\n/g, "<br />");
  };
})(this);

this.getAllComments = (function(_this) {
  return function() {
    return api.get({
      id: opts.page_id
    }, function(data) {
      _this.comments = data;
      return _this.update();
    });
  };
})(this);

this.editComment = (function(_this) {
  return function(comment) {
    comment.editing = true;
    return _this.update();
  };
})(this);

this.updateComment = (function(_this) {
  return function(comment, input) {
    comment.comments = input.target.value;
    return _this.update();
  };
})(this);

this.publishComment = (function(_this) {
  return function(comment, publish) {
    return api.save({
      id: comment.id,
      publish: publish
    }, function(data) {
      _this.getAllComments();
      _this.update();
      return _this.update();
    });
  };
})(this);

this.saveComment = (function(_this) {
  return function(comment) {
    return api.save(comment, function(data) {
      _this.getAllComments();
      _this.update();
      return _this.update();
    });
  };
})(this);

this.removeComment = (function(_this) {
  return function(comment) {
    return api.remove(comment, function(data) {
      _this.getAllComments();
      return _this.update();
    });
  };
})(this);
});
    
  