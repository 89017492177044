
    var riot = require('riot')
    
riot.tag2('mydetails', '<div class="profile"> <form class="form-horizontal"> <div class="row"> <div class="col-xs-12"> <forminput name="first_name" label="First Name" onupdate="{handleUpdate}" value="{user.first_name}" width1="3" width2="9"></forminput> <forminput name="surname" label="Surname" onupdate="{handleUpdate}" value="{user.surname}" width1="3" width2="9"></forminput> <forminput name="email" label="Email" onupdate="{handleUpdate}" value="{user.email}" width1="3" width2="9"></forminput> <formtextarea name="address" label="Address" onupdate="{handleUpdate}" value="{user.address}" width1="3" width2="9"></formtextarea> <forminput name="county" label="County" onupdate="{handleUpdate}" value="{user.county}" width1="3" width2="9"></forminput> <forminput name="postcode" label="Postcode" onupdate="{handleUpdate}" value="{user.postcode}" width1="3" width2="9"></forminput> <forminput name="country" label="Country" onupdate="{handleUpdate}" value="{user.country}" width1="3" width2="9"></forminput> <forminput name="phone1" label="Telephone" onupdate="{handleUpdate}" value="{user.phone1}" width1="3" width2="9"></forminput> <forminput name="phone2" label="Mobile" onupdate="{handleUpdate}" value="{user.phone2}" width1="3" width2="9"></forminput> <div class="row"> <div class="col-sm-9 col-sm-offset-3"> <button onclick="{handleSaveProfile}" class="btn btn-primary">Update My Profile</button> </div> </div> <p><br></p> <div if="{message}" class="alert alert-success">{message}</div> </div> </div> </form> </div>', '', '', function(opts) {
var profileApi, resource;

resource = require('riot-kit/utils/apiResource.coffee');

profileApi = new resource('/duocms/api/users/me', true);

this.on("mount", function() {
  if (window.location.search.match(/editmode=/)) {
    return this.root.innerHTML = "";
  } else {
    return profileApi.get((function(_this) {
      return function(user) {
        _this.user = user;
        return _this.update();
      };
    })(this));
  }
});

this.handleLoggedOut = (function(_this) {
  return function() {
    _this.tags.profile.hide();
    return setTimeout(function() {
      return window.location.pathname = '/';
    }, 500);
  };
})(this);

this.close = (function(_this) {
  return function() {};
})(this);

this.handleUpdate = (function(_this) {
  return function(update) {
    _this.user[update.name] = update.value;
    return _this.update();
  };
})(this);

this.handleSaveProfile = (function(_this) {
  return function(e) {
    e.preventDefault();
    _this.user.id = null;
    return profileApi.save(_this.user, function(data) {
      _this.update({
        message: "Your profile has been successfully updated."
      });
      return setTimeout((function() {
        return _this.update({
          message: false
        });
      }), 3000);
    });
  };
})(this);
});
    
  