window.riot = riot = require 'riot'
require 'riot-kit/lib/slideshow'
require "riot-kit/lib/img_loader"
require 'riot-kit/lib/form'
require 'riot-kit/lib/twitter'
require 'riot-kit/lib/modal'
require 'riot-kit/lib/form'
require 'riot-kit/lib/ecommerce'
require 'riot-kit/lib/pagination'
require 'riot-kit/lib/dateformat'
require 'riot-kit/lib/booking-calendar'
require 'riot-kit/lib/dropdown'
require 'riot-kit/lib/gmap'
require('riot-kit/lib/auth/forgot-password')
require 'riot-kit/lib/social-share'
require 'riot-kit/lib/tags'

require './tags/loginbar.tag'
require './tags/ecommercebar.tag'
require './tags/navigation.tag'
require './tags/mydetails.tag'
require './tags/mypassword.tag'
require './tags/signup.tag'
require './tags/tags-input.tag'
require './tags/comments_input.tag'
require './tags/comments_output.tag'
require './tags/recaptcha.tag'
require './tags/pricetag.tag'
require './libs/slideshow.coffee'

require './tags/eventcalendar.tag'
require './tags/site-search.tag'
require './tags/org-cards.tag'
require './tags/profile.tag'
require './tags/user-tags.tag'
require './tags/uploadimage.tag'
require './tags/login.tag'

require './tags/pagemeta.tag'

require './tags/bookdelegates.tag'

require './tags/checkout.tag'

require './tags/change_password_newuser.tag'

riot.mount('*')
document.addEventListener 'duocms:updated', -> riot.mount('slideshow,gmap')

searchbtn = document.getElementById('btn-mobilesearch')
searchblock = document.querySelector('.mobilesearch')
searchbtn.onclick = (e)->
  if searchblock.className.indexOf('open')>-1
    searchblock.className = searchblock.className.replace(/ ?open ?/,'')
  else
    searchblock.className = searchblock.className.replace(/ ?open ?/,'')+' open'

# document.addEventListener 'duocms:updated',->
#   riot.mount('*')

iboxes = document.querySelectorAll('.showimagecheck')
if iboxes?.length
  [].forEach.call iboxes,(ib)->
    ib.onclick = (e)->
      console.log('checkd', '#'+ib.getAttribute('data-opt'),  +Boolean(ib.checked))
      document.querySelector('#'+ib.getAttribute('data-opt')).value = +Boolean(ib.checked)

document.querySelector('.blog_date_ranges .blog_show_more')?.addEventListener 'click', (e)->
  date_ranges = document.querySelector('.blog_date_ranges')
  if (/open/).test(date_ranges.className)
    date_ranges.className = date_ranges.className.replace(/ open/, '') 
  else
    date_ranges.className += ' open'


formGroups = document.querySelectorAll('.register[duo-form] form .form-group')
if formGroups.length?
  [].forEach.call formGroups, (formGroup, idx, formGroups)->
    input = formGroup.querySelector('input[required]')
    if input? 
      input.onblur = (i)->
        valid = input.checkValidity()
        formGroup.className = formGroup.className.replace(/ ?has-error ?/,'') + if !valid then ' has-error' else '' 

confirmationForm = document.querySelector('form[name=eula]')

if confirmationForm? 
  link = confirmationForm.querySelector('.iconlink')
  checkbox = confirmationForm.querySelector('input')
  link.style.display = 'none'

  checkbox.onchange = (i)->
    link.style.display = if i.target.checked then 'block' else 'none'


triggers = document.querySelectorAll('.metatrigger')

if triggers && triggers.length > 0
  [].map.call triggers, (trigger)=>
    target = trigger.dataset?.trigger || null
    if target != null
      metainput = document.querySelector('input[name="page.meta.'+target+'"]')
      trigger.addEventListener 'click',(e)=>
        console.log e.target
        metainput.value = trigger.checked ? 1 : 0