
    var riot = require('riot')
    
riot.tag2('org-cards', '<div each="{org in orgs}" class="col-sm-4 org"> <div onclick="{parent.handleClick.bind(null, org)}" class="org_box"> <div class="col-xs-5"><img riot-tag="img_loader" riot-src="/images/org-logos/{org.id}.jpg" default="/images/org-logos/default.jpg" width="60px"></div> <div class="col-xs-7"> <h4>{org.name}</h4> </div> </div> </div> <org-card org="{selectedOrg}"></org-card>', '.org { padding: 10px; } .org .org_box { background: #fff; border-radius: 10px; padding: 15px; cursor: pointer; height: 110px; } .org .org_box h4 { padding: 0; }', '', function(opts) {
this.orgs = [];

this.on('mount', function() {
  var k, ref, v;
  ref = opts.orgs;
  for (k in ref) {
    v = ref[k];
    this.orgs.push(v);
  }
  return this.update();
});

this.handleClick = (function(_this) {
  return function(selectedOrg) {
    _this.selectedOrg = selectedOrg;
    _this.update();
    return _this.tags['org-card'].open();
  };
})(this);
});
riot.tag2('org-card', '<modal title="{opts.org.name}"> <div if="{parent.show == \'all\'}" class="org_details row"> <div each="{user in parent.opts.org.users}" onclick="{parent.parent.handleClick.bind(null, user)}" class="col-md-4"> <p>{user.first_name} {user.surname}</p> </div> </div> <div if="{parent.show == \'user\'}" class="row"> <div class="col-xs-6"><img riot-tag="img_loader" riot-src="/images/avatars/{parent.selectedUser.id}.jpg" default="/images/avatars/default.jpg"></div> <div class="col-xs-6"> <p>{parent.selectedUser.first_name} {parent.selectedUser.surname}</p> <p>{parent.selectedUser.phone1}</p> <p><a href="{\'mailto:\' + parent.selectedUser.email}">{parent.selectedUser.email}</a></p> </div> </div> </modal>', '.org_details { cursor: pointer; }', '', function(opts) {
this.open = (function(_this) {
  return function() {
    _this.show = 'all';
    _this.update();
    return _this.tags.modal.show();
  };
})(this);

this.handleClick = (function(_this) {
  return function(selectedUser) {
    _this.selectedUser = selectedUser;
    _this.show = 'user';
    return _this.update();
  };
})(this);
});
    
  