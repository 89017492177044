
    var riot = require('riot')
    
riot.tag2('login', '<div class="col-xs-10 col-xs-offset-1"> <signin onsignin="{handleSignedIn}" hidebutton="{true}" hidesignup="{true}" hideforgotpassword="{opts.hideforgotpassword}" forgot-password-custom-message="{opts.forgotPasswordCustomMessage}" redirect="{opts.onLoginRedirect}"></signin> <div onclick="{handleSignIn}" class="btn btn-success pull-right">Sign In</div> </div>', '', '', function(opts) {
this.on('mount', function() {
  return this.title = 'Sign In';
});

this.handleSignIn = (function(_this) {
  return function() {
    return _this.tags.signin.signin();
  };
})(this);

this.handleSignedIn = (function(_this) {
  return function() {
    return setTimeout(function() {
      return window.location.pathname = '/extranet/';
    }, 500);
  };
})(this);
});
    
  