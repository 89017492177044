
    var riot = require('riot')
    
riot.tag2('user_tags_input', '<div class="col-xs-6 current-tags"> <h5>My Interests</h5> <p if="{currentTags.length == 0}">None Selected</p> <div each="{tag in currentTags}" onclick="{parent.removeTag.bind(null,tag)}" class="badge">{tag.label} <i class="fa fa-times remove"></i></div> </div> <div class="col-xs-6 all-tags"> <h5>Pick Interests</h5> <p if="{allTags.length == 0}">None found</p> <div each="{tag in allTags}" onclick="{parent.useTag.bind(null, tag)}" class="badge {\'grayout\':parent.currentTags.indexOf(tag) &gt; -1}">{tag.label} <i if="{!(parent.currentTags.indexOf(tag) &gt; -1)}" class="fa fa-plus add"></i></div> </div>', 'user_tags_input h5 { font-weight: bold; } user_tags_input .all-tags, user_tags_input .current-tags { overflow: hidden; } user_tags_input .all-tags .badge, user_tags_input .current-tags .badge { cursor: pointer; margin-right: 5px; clear: left; float: left; margin-bottom: 2px; } user_tags_input .all-tags .badge.grayout { opacity: 0.4; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)"; filter: alpha(opacity=40); } user_tags_input .all-tags .badge:hover .add { display: inline; } user_tags_input .all-tags .badge .add { display: none; } user_tags_input .current-tags .badge:hover .remove { display: inline; } user_tags_input .current-tags .badge .remove { display: none; }', '', function(opts) {
var api, resource,
  indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

resource = require('riot-kit/utils/apiResource.coffee');

api = new resource('/duocms/api/tags', true);

this.on('mount', function() {
  this.currentTags = [];
  return this.getAllTags();
});

this.getAllTags = (function(_this) {
  return function() {
    return api.get(function(data) {
      _this.allTags = data;
      _this.allTags.map(function(t) {
        var ref;
        if (ref = t.label, indexOf.call(typeof opts !== "undefined" && opts !== null ? opts.tags : void 0, ref) >= 0) {
          return _this.currentTags.push(t);
        }
      });
      if (opts.onupdate) {
        opts.onupdate(_this.currentTags);
      }
      return _this.update();
    });
  };
})(this);

this.useTag = (function(_this) {
  return function(tag) {
    _this.currentTags.push(tag);
    _this.currentTags = _this.currentTags.filter(function(t, idx) {
      return _this.currentTags.indexOf(t) === idx;
    });
    if (opts.onupdate) {
      opts.onupdate(_this.currentTags);
    }
    return _this.update();
  };
})(this);

this.removeTag = (function(_this) {
  return function(tag) {
    _this.currentTags = _this.currentTags.filter(function(t) {
      return tag.id !== t.id;
    });
    if (opts.onupdate) {
      opts.onupdate(_this.currentTags);
    }
    return _this.update();
  };
})(this);
});
    
  