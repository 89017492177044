
    var riot = require('riot')
    
riot.tag2('comments_input', '<form if="{!successful &amp;&amp; !error}" onsubmit="{addComment}" class="form"> <div class="col-xs-12 col-sm-6"> <div class="form-group"> <label class="control-label">Name</label> <input name="name" type="text" required class="form-control"> </div> </div> <div class="col-xs-12 col-sm-6"> <div class="form-group"> <label class="control-label">Email</label> <input name="email" type="text" required class="form-control"> </div> </div> <div class="col-xs-12"> <div class="form-group"> <label class="control-label">Comments</label> <textarea name="comments" required rows="5" class="form-control"></textarea> </div> </div> <div class="col-xs-12"> <div class="form-group"> <recaptcha key="{opts.key}" onupdate="{tokenUpdate}" name="blog_comment"></recaptcha> </div> </div> <div class="col-xs-12"> <button type="submit" class="btn btn-primary">Send</button> </div> </form> <div if="{successful}" class="alert alert-success"> <p>Thank you, your comment has been sent for moderation.</p> </div> <div if="{error}" class="alert alert-success"> <p>Sorry, there was a problem sending your comment. Please try again later.</p> </div>', '', '', function(opts) {
var api, resource;

resource = require('riot-kit/utils/apiResource.coffee');

api = new resource('/duocms/api/comments', true);

this.tokenUpdate = (function(_this) {
  return function(token) {
    _this.token = token;
  };
})(this);

this.addComment = (function(_this) {
  return function() {
    var newComment;
    newComment = {
      page_id: opts.page_id,
      name: _this.name.value,
      email: _this.email.value,
      comments: _this.comments.value,
      token: _this.token
    };
    return api.save(newComment, function(data, err) {
      if (err != null) {
        _this.error = true;
        _this.update();
        console.log('comment unsucessfull', err);
        return;
      } else {
        _this.successful = true;
        _this.reset();
      }
      return setTimeout(function() {
        _this.error = false;
        _this.successful = false;
        return _this.update();
      }, 4000);
    });
  };
})(this);

this.reset = (function(_this) {
  return function() {
    _this.name.value = "";
    _this.email.value = "";
    _this.comments.value = "";
    _this.token = null;
    _this.tags.blog_comment.reset();
    return _this.update();
  };
})(this);
});
    
  